
import { TextField } from "@mui/material";
import { useEffect, useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";
import HandlerApp from "../../../utils/handlerApp";
import { useForm, Controller } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";

const CategoriesForms = () => {
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const endpoint = "administration/categories";
    const [t, i18n] = useTranslation("global");
    // const title = t('categoryComponent.createCategory');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    // Permisos
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [disableButton, setDisableButton] = useState(false);
    const { app } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        setTitle(t('categoryComponent.createCategory'));
        setDescription(t('description.category_create'));
        if (id !== undefined) {
            setTitle(t('categoryComponent.updateCategory'));
            setDescription(t('description.category_update'));
        }
    }, [t])

    // utils
    const api = useFetch();
    const nav = useNavigate();
    const { id } = useParams();
    // data
    const [data, setData] = useState([]);
    // estado inicial de formulario
    const [formData, setFormData] = useState({
        category_name: "",
        category_name_spanish: "",
        category_description: "",
        category_description_spanish: "",
        category_image: "",
        category_slug: "",
        category_num_products: ""
    });

    const [images, setImages] = useState([]);
    const handleImages = (e) => {
        setImages(e)
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                    setDisableButton(false)
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    useEffect(() => {
        handlerApp.setLoader(true);
        if (id !== undefined) {
            sendRequest();
        } else {
            handlerApp.setLoader(false);
        }
    }, [])

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);

        if (dependency !== null) {
            dependency(e.target.value);
        }
    }
    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitLocal = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData, category_image: images
        }
        api.post(endpoint, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleUpdate = (data) => {
        handlerApp.setLoader(true);
        let body = {
            ...formData, category_image: images
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description}</p>
            </div>

            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} noValidate onKeyDown={handleKeyDow}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('englishName')+" *"} variant="standard" size="small"
                                {...register("category_name", { required: true, maxLength: 80 })}
                                value={formData.category_name}
                                onChange={handleForm}
                                error={errors.category_name != null}
                                helperText={
                                    errors.category_name?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.category_name?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('spanishName')+" *"}
                                variant="standard"
                                size="small"
                                {...register("category_name_spanish", { required: true, maxLength: 80 })}
                                value={formData.category_name_spanish}
                                onChange={handleForm}
                                error={errors.category_name_spanish != null}
                                helperText={
                                    errors.category_name_spanish?.type === 'required' ? t('validationErrorMessages.required') :
                                        errors.category_name_spanish?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 80 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField
                                label={t('englishDescription')}
                                size="small"
                                {...register("category_description", { maxLength: 500 })}
                                value={formData.category_description}
                                onChange={handleForm}
                                multiline rows={3}
                                error={errors.category_description != null}
                                helperText={
                                    errors.category_description?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 500 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />
                        </div>

                        <div className="col-md-6 mb-2">

                            <TextField
                                label={t('spanishDescription')}
                                size="small"
                                {...register("category_description_spanish", { maxLength: 500 })}
                                value={formData.category_description_spanish}
                                onChange={handleForm}
                                multiline rows={3}
                                error={errors.category_description_spanish != null}
                                helperText={
                                    errors.category_description_spanish?.type === 'maxLength' ? `${t('validationErrorMessages.long')} 500 ${t('validationErrorMessages.longSuffix')}` : ""
                                }
                            />

                        </div>
                        <div className="col-md-12 mb-2">
                            <BoldFilesUpload returnImages={handleImages} multiple={false} value={formData.category_image} />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CategoriesForms;