import React, { useEffect, useRef } from 'react';
import '../../../assets/css/textField.css';

const EditTextCell = ({ id, data, name, product, onChange }) => {
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.value = data;
        }
    }, [data]);

    const isValidNumberInput = (name, value) => {
        if (name === "product_price" || name === "productlocation_stock") {
            const regex = /^\d+(\.\d{0,2})?$/;
            return regex.test(value) || value === '';
        }
        return true; // Para otros campos, siempre es válido
    };

    const changeValue = (e) => {
        const value = e.target.value;
        if (isValidNumberInput(name, value)) {
            if (onChange && typeof onChange === 'function') {
                onChange(id, name, value, product);
            }
        } 
    };

    const handleKeyPress = (e) => {
        if (name === "product_price" || name === "productlocation_stock") {
            const char = String.fromCharCode(e.which);
            const value = e.target.value;

            if (!/^\d$/.test(char) && char !== '.' && char !== 'Backspace') {
                e.preventDefault();
            }

            // Prevent more than one decimal point
            if (char === '.' && value.includes('.')) {
                e.preventDefault();
            }

            // Allow only up to two decimal places
            const [integerPart, decimalPart] = value.split('.');
            if (decimalPart && decimalPart.length >= 2 && e.target.selectionStart > value.indexOf('.')) {
                e.preventDefault();
            }
        }
    };

    return (
        <div className="custom-text-field">
            <input
                ref={inputRef}
                type="text"
                onChange={changeValue}
                onKeyPress={handleKeyPress}
            />
        </div>
    );
};

export default EditTextCell;
