import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { AppContext } from "../../../context/AppContext";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import InfiniteAddItems from "../../../utils/InfiniteAddItems/InfiniteAddItems";
import { LocationContext } from "../../../context/LocationContext";
import Swal from "sweetalert2";
import { useForm, Controller, set } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { Description } from "@mui/icons-material";

const TaxesGroup = () => {
  const {register, handleSubmit, formState: { errors }, setValue, control} = useForm();
  const endpoint = "web/configuration/taxes";
  const [t, i18n] = useTranslation("global");
  const api = useFetch();
  const nav = useNavigate();
  const { id } = useParams();
  const handlerApp = HandlerApp();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const [data, setData] = useState();

  // Permisos
  const [permissions, setPermissions] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const { app } = useContext(AppContext);
  useEffect(() => {
    setPermissions(app.permissions[endpoint]);
  }, [app]);

  useEffect(() => {
    setTitle(t("taxesComponent.tax_create"));
    setDescription(t("description.taxes_create"));
    if (id !== undefined) {
      setTitle(t("taxesComponent.tax_update"));
      setDescription(t("description.taxes_update"));
    }
  }, [t]);

  // location
  const { checkLocations,locations } = useContext(LocationContext);
  const [locationOptions, setLocationOptions] = useState([])
  /**
   * Se Comenta este codigo ya que en esta vista no interfire el cambio de sucursal
      const validateLocation = true;
      useEffect(() => {
        if (app.hasOwnProperty("location")) {
          if (validateLocation) {
            // valida si esta activa la verificacion del sucursal.
            if (location === "") {
              // no hay sucursal seleccionada
              handlerApp.showAlertLocation(); // muestra una notificacion al usuario solicitando que por favor seleccione una sucursal.
              setData([]);
            } else {
              handlerApp.setLoader(true);
              if (id !== undefined) {
                sendRequest();
              } else {
                handlerApp.setLoader(false);
              }
            }
          } else {
            sendRequest(); // agrego xq esta pantalla depende de las sucursales en el estado.
          }
        }
      }, [location]);
   */

  // Data
  const [formData, setFormData] = useState({
    taxgroup_name: "",
    location_id: "",
    taxes: [],
  });

  const handleDependencies = (data) => {
    let d = filterData(data, formData);
    setFormData(d);
    setData(d);
  };

  const filterData = (data, formData) => {
    let finalData = {};
    for (let key in formData) {
      finalData[key] = data[key];
      setValue(key, data[key]);
    }
    return finalData;
  };

  const fields = [
    {
      type: "text",
      name: "tax_name",
      label: t("taxesComponent.tax_name"),
      variable: "text",
    },
    {
      type: "percent",
      name: "tax_percentage",
      label: t("taxesComponent.tax_percentage"),
      variable: "numeric",
    },
  ];

  const sendRequest = () => {
    api
      .get(endpoint + "/" + id)
      .then((response) => {
        if (response.success) {
          let data = response.data[0];
          handleDependencies(data);
          handlerApp.setLoader(false);
          setDisableButton(false)
        } else {
          handlerApp.showError(response.message);
          setDisableButton(true)
          handlerApp.setLoader(false);
        }
      })
      .catch((error) => {
        handlerApp.showError();
        handlerApp.setLoader(false);
      });
  };
  useEffect(() => {
    handlerApp.setLoader(true);
    if (id !== undefined) {
      sendRequest();
    } else {
      handlerApp.setLoader(false);
    }
    checkLocations()
  }, []);

  const handleInfiniteAdd = (result, name) => {
    setFormData({ ...formData, [name]: result });
    setValue(name, result);
  };

  // --------------- FORM -----------------
  const handleForm = (e, name = null, dependency = null) => {
    let key = name !== null ? name : e.target.name;
    setFormData({
      ...formData,
      [key]: e.target.value,
    });
    setValue(key, e.target.name);
    if (dependency !== null) {
      dependency(e.target.value);
    }
  };

  const back = () => {
    nav(-1);
  };

  // save data
  const handleSubmitLocal = (data) => {
    handlerApp.setLoader(true);
    if (data.taxes.length > 0) {
      const isSomeTaxEmpty = data.taxes.some(tax => tax.tax_name === '' || tax.tax_percentage === null);
      if (!isSomeTaxEmpty) {
        api
        .post(endpoint, data)
        .then((response) => {
          if (response.success) {
            handlerApp.showOk(response);
            handlerApp.setLoader(false);
            nav("/" + endpoint)
          } else {
            handlerApp.handlerResponse(response);
            handlerApp.setLoader(false);
          }
        })
        .catch((error) => {
          handlerApp.showError();
          handlerApp.setLoader(false);
        });
      }else {
        Swal.fire({
          title: "",
          text: t("taxesComponent.tax_empty"),
          icon: "warning",
        });
        handlerApp.setLoader(false);
      }
    } else {
      Swal.fire({
        title: "",
        text: t("W102"),
        icon: "warning",
      });
      handlerApp.setLoader(false);
    }
  };

  const handleUpdate = (data) => {
    handlerApp.setLoader(true);
    if (data.taxes.length > 0) {
      const isSomeTaxEmpty = data.taxes.some(tax => tax.tax_name === '' || tax.tax_percentage === null);
      if (!isSomeTaxEmpty) {
          api
            .put(endpoint + "/" + id, data)
            .then((response) => {
              if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
              } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
              }
            })
            .catch((error) => {
              handlerApp.showError();
              handlerApp.setLoader(false);
            });
        } else {
          Swal.fire({
            title: "",
            text: t("taxesComponent.tax_empty"),
            icon: "warning",
          });
          handlerApp.setLoader(false);
        }
      } else {
        Swal.fire({
          title: "",
          text: t("W102"),
          icon: "warning",
        });
        handlerApp.setLoader(false);
      }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    nav(-1);
  };

  const handleKeyDow = (event) => {
    // Verifica si la tecla presionada es Enter
    if (event.key === 'Enter') {
      // Evita el comportamiento predeterminado del formulario
      event.preventDefault();
    }
  };

  const transformLocations = () => {
    let data = locations.map((location) => {
      return {
        code: location.location_id,
        name: location.location_name,
      };
    });
    setLocationOptions(data)
  }

  useEffect(() => {
    transformLocations()
  }, [locations])

  return (
    <div className="app container">
      <span className="material-symbols-outlined" role="button" onClick={back}>
        arrow_back
      </span>
      <div className="title-section-forms">
        <h3>{title}</h3>
        <p>{description} </p>
      </div>
      <div className="section-forms">
        <form
          onSubmit={handleSubmit(
            id === undefined ? handleSubmitLocal : handleUpdate
          )}
          // noValidate
          onKeyDown={handleKeyDow}
        >
          <div className="row">
            <div className="col-md-6 mb-2">
              <TextField
                label={t("taxesComponent.taxgroup_name")+" *"}
                variant="standard"
                size="small"
                {...register("taxgroup_name", {
                  required: true,
                  maxLength: 80,
                })}
                value={formData.taxgroup_name}
                onChange={handleForm}
                error={errors.taxgroup_name != null}
                helperText={
                  errors.taxgroup_name?.type === "required"
                    ? t("validationErrorMessages.required")
                    : errors.taxgroup_name?.type === "maxLength"
                      ? `${t("validationErrorMessages.long")} 80  ${t(
                        "validationErrorMessages.longSuffix"
                      )}`
                      : ""
                }
              />
            </div>
            <div className="col-md-6 mb-2">
                <BoldSelectValidated
                    title={t('locationComponent.location') + " *"}
                    value={formData.location_id}
                    name="location_id" 
                    options={locationOptions}
                    onChange={handleForm}
                    register={register}
                    errors={errors}
                    required
                />
                
            </div>
            <div className="col-md-12 mb-2 ph-20">
              <InfiniteAddItems
                name="taxes"
                fields={fields}
                values={formData.taxes}
                onChange={handleInfiniteAdd}
                title={t("taxesComponent.tax_associated")}
                requestChange={data}
              />
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-md-3 d-flex justify-content-around">
                <button
                  className="bold-btn bold-btn-light"
                  onClick={handleCancel}
                >
                  {t("cancel")}
                </button>

                {id === undefined && (
                  <button className="bold-btn bold-btn-dark" type="submit">
                    {t("submit")}
                  </button>
                )}
                {id && !disableButton && (
                  <button className="bold-btn bold-btn-dark" type="submit">
                    {t("update")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default TaxesGroup;
