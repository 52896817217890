import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { AppContext } from "../../../context/AppContext";
import { createTheme, FormControl, FormLabel, ThemeProvider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LocationContext } from "../../../context/LocationContext";
import SelectLocation from "../../molecules/SelectLocation";
import { useForm, Controller } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";
import { TextField } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import Swal from "sweetalert2";

const CloseoutConfiguration = () => {

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()
    const endpoint = "configuration/closeout";
    const [t, i18n] = useTranslation("global");
    // const title = t('CloseoutComponent.CloseoutConfiguration');
    const api = useFetch();
    const nav = useNavigate();
    // const [locationId, setLocationId] = useState("");
    const handlerApp = HandlerApp();
    const [data, setData] = useState([]);
    const [closeoutTime, setCloseoutTime] = useState(null);
    const [closeout, setCloseout] = useState("");
    const [update, setUpdate] = useState(false);
    const [disableButton, setDisableButton] = useState(false);

    // Permisos
    const [permissions, setPermissions] = useState([]);
    const { app,modeTheme } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    const handleLocationChange = () => {
        setCloseoutTime(''); // Establece el estado en blanco
        setUpdate(false);
    };

    // location
    const { location } = useContext(LocationContext);
    const validateLocation = true;

    useEffect(() => {
        if (location === "") {
            if (handlerApp.getLocation() === null || handlerApp.getLocation() === "") {
                handlerApp.showAlertLocation();
            }
        } else {
            sendRequest();
        }
        handleLocationChange();
    }, [location])

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
          primary: {
            main: '#90caf9',
          },
          secondary: {
            main: '#f48fb1',
          },
          background: {
            default: '#303030',
            paper: '#424241',
          },
          text: {
            primary: '#ffffff',
            secondary: '#bfbece',
          },
        },
      }, 'dark');

    // Data
    const [formData, setFormData] = useState({
        closeoutlocation_time: ""
    });

    const handleDependencies = (data) => {
        setData(data);
        let d = handlerApp.filterData(data, formData);
        setFormData(d);
    };

    const sendRequest = () => {
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + location)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    const closeoutTime = data?.closeoutlocation_time || "";
                    if (closeoutTime !== "") {
                        setCloseoutTime(moment.parseZone(formatCloseoutTime(closeoutTime), "hh:mm"));
                        setUpdate(true);
                    } else {
                        setCloseoutTime(null);
                        setUpdate(false);
                    }
                    setData(response.data);
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    setCloseoutTime(null);                 
                    handlerApp.setLoader(false);
                    // handlerApp.handlerResponse(response);
                    setUpdate(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError();
            });
    };

    const formatCloseoutTime = (closeoutTime) => {
        const [hora, minutos] = closeoutTime.split(":");
        return `${hora}:${minutos}`;
    };
    useEffect(() => {
        if (closeout?.hasOwnProperty('_d')) {
            setCloseoutTime(closeout);
        }
    }, [closeout]);
    const [messageError, setMessageError] = useState('');

    useEffect(() => {
        if(!closeoutTime || closeoutTime == "Invalid date"){
            setDisableButton(true);
        }else {
            setDisableButton(false);
            setMessageError('')
        }
    }, [closeoutTime]);

    const errorMessage = useMemo(() => {
        switch (messageError) {
          case 'invalidDate': {
            return t('validationErrorMessages.invalidHour');
          }
          default: {
            return '';
          }
        }
      }, [messageError]);
    

    const back = () => {
        nav(-1);
    }

    
    // save data
    const handleSubmitLocal = (data) => {
        if(!closeoutTime || closeoutTime == "Invalid date"){
            Swal.fire({
                icon: 'error',
                title: '',
                text: t('validationErrorMessages.emptyFieldError'),
            });
        }else{

            handlerApp.setLoader(true);
            let body = {
                ...formData,
                location_id: location,
                closeoutlocation_time: closeoutTime?.format('HH:mm')
            }
    
            handlerApp.setLoader(true);
            api.post(endpoint, body)
                .then((response) => {
                    if (response.success) {
                        handlerApp.showOk(response);
                        handlerApp.setLoader(false);
                        setUpdate(true);
                        setData(response.data);
                    } else {
                        setData([]);
                        // handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }
                })
                .catch((error) => {
                    handlerApp.showError();
                    handlerApp.setLoader(false);
                });
        }
    };


    const handleUpdate = (data) => {
        if(!closeoutTime || closeoutTime == "Invalid date"){
            Swal.fire({
                icon: 'error',
                title: '',
                text: t('validationErrorMessages.emptyFieldError'),
            });
        }else{
            handlerApp.setLoader(true);
            const body = {
                ...formData,
                closeoutlocation_time: closeoutTime?.format('HH:mm'),
                location_id: location
                }
                
            api.put(endpoint + "/" + body.location_id, body) // Realiza una solicitud PUT con el cuerpo que incluye el ID
                .then((response) => {
                 console.log({response});

                    if (response.success) {
                        handlerApp.showOk(response);
                        handlerApp.setLoader(false);
                    } else {
                        // handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }
                })
                .catch((error) => {
                    handlerApp.showError(error);
                    handlerApp.setLoader(false);
                });
        }
    };

    const deleteRow = (e) => {
        e.preventDefault();
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + location)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    sendRequest();
                    setCloseoutTime("00:00");
                    setUpdate(false);
                } else {
                    // handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };

    const timeFormat = (value) => {
        setCloseout(value)        
    }
    return (
        <>
            {location === "" && (
                <SelectLocation back={back} />
            )}
            {location !== "" && (
                <div className="app container">
                    <div className="section-forms">
                        <form onSubmit={handleSubmit(update ? handleUpdate : handleSubmitLocal)} noValidate onKeyDown={handleKeyDow}>
                            <div className="row">
                                <div className="col-md-12 mb-2 ph-20">
                                    <FormControl className="w-100" variant="standard" size="small">
                                        <div className="row">
                                            <div className="d-flex flex-column mb-2">
                                                <FormLabel id="product_view" >{t('CloseoutComponent.configuration')}</FormLabel>
                                                <span className="text-gray light small-text mb-3">{t('CloseoutComponent.configurationDescription')}</span>
                                            </div>
                                            
                                        </div>
                                        <div className="row align-items-center d-flex">
                                            <div className="col-10 col-md-4">
                                            
                                                {modeTheme ? 
                                                <ThemeProvider theme={darkTheme}>
                                                    <TimePicker
                                                        ampm={false}
                                                        openTo="hours"
                                                        views={[ 'hours','minutes']}
                                                        format="HH:mm"
                                                        label={t('CloseoutComponent.closing time')+" (hh:mm)"}
                                                        value={closeoutTime}
                                                        onChange={(e) => {timeFormat(e)}}
                                                        onError={(newError) => {
                                                            setMessageError(newError);
                                                        }}
                                                        slotProps={{
                                                            textField: {
                                                              helperText: errorMessage,
                                                              required: true,
                                                            },
                                                        }}
                                                    />
                                                </ThemeProvider>
                                                :
                                                <TimePicker
                                                    ampm={false}
                                                    openTo="hours"
                                                    views={[ 'hours','minutes']}
                                                    format="HH:mm"
                                                    label={t('CloseoutComponent.closing time')+" (hh:mm)"}
                                                    value={closeoutTime}
                                                    onChange={(e) => setCloseout(e)}
                                                    onError={(newError) => {
                                                        setMessageError(newError);
                                                    }}
                                                    slotProps={{
                                                        textField: {
                                                          helperText: errorMessage,
                                                          required: true,
                                                        },
                                                    }}
                                                />
                                                }
                                            </div>

                                            <div className="col-2 col-md-8 p-0">
                                                {update && !disableButton && <button className="btn-table-actions " onClick={deleteRow}>
                                                    <span className="material-symbols-outlined">delete</span>
                                                </button>}
                                            </div>
                                            
                                        </div>

                                        <div className="d-flex align-items-center justify-content-start col-12 alert alert-warning">
                                            <span className="material-symbols-outlined m-1">report_problem</span>
                                            <span className="text-black light medium-text mt-1 ms-3">{t('CloseoutComponent.configurationWarning')}</span>
                                        </div>


                                        <div className="d-flex justify-content-around">
                                            {update ? (
                                                <button className="bold-btn bold-btn-dark" onClick={handleSubmit}>
                                                    {t('update')}
                                                </button>
                                            ) : (
                                                <button className={`bold-btn bold-btn-dark ${disableButton && "disabled"}`} onClick={handleSubmit} disabled={disableButton}>
                                                    {t('submit')}
                                                </button>
                                            )}
                                        </div>
                                        
                                    </FormControl>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            )}
        </>
    )
}

export default CloseoutConfiguration;