
import { useEffect, useState, useMemo, useContext } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";
import NoDataTables from "../../noDataTable/NoDataTables";

const AlarmsConfiguration = () => {
    const endpoint = "orders/alarmsConfiguration";
    const [t,i18n] = useTranslation("global");
    const title = t('alarmsConfigurationComponent.alarmsConfiguration');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    
    const [permissions,setPermissions] = useState([]);
    
    const {app} = useContext(AppContext);

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id, action)
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
        }
    }

    const deleteRow= (id)=>{
        Swal.fire({
            icon: 'info',
            title: `${t('DeleteAlert.DeleteRecord')}`,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: `${t('DeleteAlert.delete')}`,
            cancelButtonText: `${t('cancel')}`,
            customClass: {
                confirmButton: 'custom-confirm-button-class',
            }
            }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                api.deleteMethod(endpoint+'/'+id)
                .then(response =>{
                    if(response.success){
                        handlerApp.showOk(response);
                        sendRequest();
                    }else{
                        handlerApp.handlerResponse(response);
                        handlerApp.setLoader(false);
                    }
                })
                .catch(error =>{
                    handlerApp.showError(t(error.message));
                    handlerApp.setLoader(false);
                })
            }
        });
    }


    const optionRequest = (id, action) => {
        Swal.fire({
            title: action === 'active' ? `${t('activeAlert.activeRecord')}` : `${t('inactiveAlert.inactiveAlert')}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: action === 'active' ? "#08a215" : "#be2a2a",
            cancelButtonColor: "#636c74",
            cancelButtonText: `${t('cancel')}`,
            confirmButtonText: action === 'active' ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`
        }).then((result) => {
            if (result.isConfirmed) {
                executeAction(id, action);
            }
        });
    }
    
    const executeAction = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const columns = [
        {
            id: 'alarmconfiguration_id',
            name: 'id',
            selector: row => row.alarmconfiguration_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'location_name',
            name: t('alarmsConfigurationComponent.location'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            id: 'alarmconfiguration_pickup',
            name: t('alarmsConfigurationComponent.alarmConfigurationPickup'),
            selector: row => handlerApp.formatMinutesToTime(row.alarmconfiguration_pickup),
            sortable: true
        },
        {
            id: 'alarmconfiguration_time_limit_normal',
            name: t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNormal'),
            selector: row => handlerApp.formatMinutesToTime(row.alarmconfiguration_time_limit_normal),
            sortable: true
        },
        {
            id: 'alarmconfiguration_time_limit_near_delay',
            name: t('alarmsConfigurationComponent.alarmConfigurationTimeLimitNearDelay'),
            selector: row => handlerApp.formatMinutesToTime(row.alarmconfiguration_time_limit_near_delay),
            sortable: true
        },
        {
            id: 'alarmconfiguration_time_delay',
            name: t('alarmsConfigurationComponent.alarmConfigurationTimeDelay'),
            selector: row => handlerApp.formatMinutesToTime(row.alarmconfiguration_time_delay),
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => {  return handlerApp.actions(row, permissions,'alarmconfiguration',handlerAction)},
            // cell:(row) => { return actions(row)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                if (response.message !== "M001") {
                    handlerApp.handlerResponse(response);
                }
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    
    useEffect(() => {
        sendRequest();
    }, [])

    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
        console.log(app);
    },[app])

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.location_name && item.location_name.toLowerCase().includes(filterText.toLowerCase())
            || item.alarmconfiguration_id.toString().toLowerCase().includes(filterText.toLowerCase())
            || handlerApp.formatMinutesToTime(item.alarmconfiguration_pickup).toString().toLowerCase().includes(filterText.toLowerCase())
            || handlerApp.formatMinutesToTime(item.alarmconfiguration_time_limit_normal).toString().toLowerCase().includes(filterText.toLowerCase())
            || handlerApp.formatMinutesToTime(item.alarmconfiguration_time_limit_near_delay).toString().toLowerCase().includes(filterText.toLowerCase())
            || handlerApp.formatMinutesToTime(item.alarmconfiguration_time_delay).toString().toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            <div className="title-section-forms">
                        <h3>{title}</h3>
                        <p className="small-text">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum, rerum sequi excepturi saepe laudantium numquam ullam rem pariatur explicabo modi nisi quas cum reprehenderit enim harum architecto natus dolorum! Aut!</p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <div className="action-buttons">
                                { permissions?.indexOf("create") !== -1 && (
                                    <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                        <span className="material-symbols-outlined">add</span>
                                    </button>
                                ) }
                                <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        noDataComponent={<NoDataTables/>}
                        // subHeaderComponent={subHeaderComponentMemo}
                        //selectableRows
                        
                    />
                </div>
            </div>
        </div>
    )
}

export default AlarmsConfiguration;