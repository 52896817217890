import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../src/context/AppContext";
import { useContext } from "react";


const BoldSelectValidated = ({title, name, options,onChange=null, value,dependency=null, register, errors, required=false,disable=false })=>{
    const [val,setVal] = useState("");
    const [t] = useTranslation("global");
    const { modeTheme } = useContext(AppContext);
   
    useEffect(()=>{
        if(value){
            if(options.length > 0){
                setVal(value);
            }
        }
    },[value])




    const handleChange = (e)=>{   
        setVal(e.target.value);
        if(onChange!= null){
            onChange(e,name,dependency);
        }
    }
   
    return (
        <FormControl className="select-form w-100" variant="standard" size="small">
            <InputLabel className="select-form-input" id={`${title}-label`}>{title}</InputLabel>
            <Select labelId={`${title}-label`} className="select-form-select" 
                 {...register(name,{ required: required})}

                value={value}
                disabled={disable}
                onChange={handleChange} 
                label={title} 
                error={errors[name]!= null}
                
            >
                <MenuItem value="">
                    <em>...</em>
                </MenuItem>
                {options.map(item => (
                    <MenuItem value={item.code} key={item.code}>
                        {item.name}
                    </MenuItem>
                ))}




                
            </Select>
            {errors[name]!= null && <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root select-form-error">{
                errors[name]?.type === 'required' ? t('validationErrorMessages.required') : ""
                }</FormHelperText>}
        </FormControl>
    )
}

export default BoldSelectValidated;