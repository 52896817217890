import { useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import { useForm, Controller } from "react-hook-form";
import BoldSelectValidated from "../../../utils/BoldSelectValidated";

const UsersCompaniesForm = () => {

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const endpoint = 'configuration/userscompanies';
    const api = useFetch();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const nav = useNavigate();
    const { id } = useParams();
    const [t, i18n] = useTranslation("global");

    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [users, setUsers] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [disableButton, setDisableButton] = useState(false);

    const [formData, setFormData] = useState({
        company_id: "",
        user_id: ""
    });

    useEffect(() => {
        setTitle(t("userCompanyComponent.createUserCompany"));
        setDescription(t("description.userCompany_create"));
        if (id !== undefined) {
            setTitle(t('userCompanyComponent.updateUserCompany'));
            setDescription(t('description.userCompany_update'));
        }
    }, [t])

    const handleDependencies = (data) => {
        // setCountry(data.country_code);
        // setState(data.state_code);
        let d = filterData(data, formData);
        // setFormData(filterData(data));
        setFormData(d);
    }
    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }
    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                    setDisableButton(false)
                } else {
                    handlerApp.showError(response.message);
                    setDisableButton(true)
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }
    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: 'getParamsUpdate'
        })
            .then(response => {
                if (response.success) {
                    setUsers(handlerApp.getResultFromObject(response.data, 'users'));
                    setCompanies(handlerApp.getResultFromObject(response.data, 'companies'));
                    if (id !== undefined) {

                        sendRequest();
                    } else {
                        handlerApp.setLoader(false);
                    }
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }, []);

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });

        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }
    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }
    const handleSubmitLocal = (data) => {

        handlerApp.setLoader(true);
        api.post(endpoint, formData)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }
    const handleUpdate = (data) => {

        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, formData)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const handleKeyDow = (event) => {
        // Verifica si la tecla presionada es Enter
        if (event.key === 'Enter') {
            // Evita el comportamiento predeterminado del formulario
            event.preventDefault();
        }
    };
    return (
        <div className="app container">
            <div className="title-section-forms">
                {title}
                <p>{description}</p>
            </div>

            <div className="section-forms">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitLocal : handleUpdate)} onKeyDown={handleKeyDow}>
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('user')+" *"}
                                value={formData.user_id}
                                name="user_id"
                                onChange={handleForm}
                                options={users}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                            {/* <BoldSelect title={t('user')} value={formData.user_id} name="user_id" options={users} onChange={handleForm} /> */}
                        </div>

                        <div className="col-md-6 mb-2">
                            <BoldSelectValidated
                                title={t('companyComponent.company')+" *"}
                                value={formData.company_id}
                                name="company_id"
                                onChange={handleForm}
                                options={companies}
                                register={register}
                                errors={errors}
                                required={true}
                            />
                            {/* <BoldSelect title={t('companyComponent.company')} value={formData.company_id} name="company_id" options={companies} onChange={handleForm} /> */}
                        </div>

                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" type="submit">{t('submit')}</button>}
                                {id && !disableButton && <button className="bold-btn bold-btn-dark" type="submit">{t('update')}</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default UsersCompaniesForm;