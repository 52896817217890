import { useEffect, useState } from "react";
import * as React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import OrderDetails from "./OrderDetails";
import moment from "moment";
// import llevar from '../../../../public/icons/llevar.png';
// import recoger from '../../../../public/icons/recoger.png';


const OrdersView = () => {
    const endpoint = "orders";
    const [t, i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [order, setOrder] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const { id } = useParams();
    useEffect(() => {
       sendRequest()
    }, [])

    useEffect(() => {
        if(order.order_state==="D"){
            setOrder((prevForm) => ({
                ...prevForm,
                order_state: "DOING",
            }));
        }else if(order.order_state==="F"){
            setOrder((prevForm) => ({
                ...prevForm,
                order_state: "FREEZE",
            }));
        }else if(order.order_state==="B"){
            setOrder((prevForm) => ({
                ...prevForm,
                order_state: "Borrador",
            }));
        }else if(order.order_state==="P"){
            setOrder((prevForm) => ({
                ...prevForm,
                order_state: "PENDING",
            }));
        }else if(order.order_state==="I"){
            setOrder((prevForm) => ({
                ...prevForm,
                order_state: "IN PROCCESS",
            }));
        }else if(order.order_state==="C"){
            setOrder((prevForm) => ({
                ...prevForm,
                order_state: "COMPLETE",
            }));
        }else if(order.order_state==="W"){
            setOrder((prevForm) => ({
                ...prevForm,
                order_state: "WITHHOLD",
            }));
        }else if(order.order_state==="O"){
            setOrder((prevForm) => ({
                ...prevForm,
                order_state: "OPEN",
            }));
        }
     }, [order])

    const sendRequest=()=>{
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let data = response.data[0];
                    data.order_detail = JSON.parse(data.order_detail)
                    setTitle(`${t('ordersComponent.order')} # ${data.order_id}`);
                    setOrder(data);
                    //console.log(data);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.setLoader(false);
            })
    }

    const back = () => {
        nav(-1);
    }

    return (
        <div className="app">
            <div className="row">
                <div className="col-md-6">
                    <div className="title-section-forms">
                        <div className="d-flex row justify-content-between align-items-center mb-3">
                            <span className="material-symbols-outlined absolute arrowBack backArrow arrow-back-icon" role="button" onClick={back}>arrow_back</span>
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                        {
                            order.order_takeout ?
                                (
                                    <img width={60} height={60} src='/icons/llevar.png' alt="Llevar" />
                                )
                                : order.order_pickup ?
                                    (
                                        <img width={60} height={60} src='/icons/recoger.png' alt="Recoger" />
                                    )
                                    :
                                    (
                                        <img width={60} height={60} src="/icons/restaurante.png" alt="Restaurante" />
                                    )
                        }
                        {title}
                        </div>
                    </div>
                    <div className="bold-container-content">
                        <div className="row">
                            <div className="col-md-4 mb-2">
                                <dt>{t('ordersComponent.order_reference')}:</dt>
                                <dd>{order.order_reference || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-4 mb-2">
                                <dt>{t('companyComponent.company')}</dt>
                                <dd>{order.company_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-4 mb-2">
                                <dt>{t('locationComponent.locationName')}</dt>
                                <dd>{order.location_name || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-4 mb-2">
                                <dt>{t('address')}</dt>
                                <dd>{order.location_address || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-4 mb-2">
                                <dt>{t('ordersComponent.orderExpiration')}</dt>
                                <dd>{moment(order.order_expiration).format("DD/MM/YY, HH:mm")}</dd>
                            </div>
                            <div className="col-md-4 mb-2">
                                <dt>{t('state')}</dt>
                                <dd>{order.order_state || <span className="no-imformation-opacity">{t('noInformation')}</span>}</dd>
                            </div>
                            <div className="col-md-4 mb-2">
                                <dt>{t('ordersComponent.type_order')}</dt>
                                <dd>
                                    {
                                        order.order_takeout ?
                                            (
                                                <p>{t('ordersComponent.takeOut')}</p>
                                            )
                                            : order.order_pickup ?
                                                (
                                                    <p>{t('ordersComponent.PickUp')}</p>
                                                )
                                                : order.order_type == 'Quick service' ? 
                                                    (
                                                        <p>{t('ordersComponent.quickService')}</p>
                                                    ) :
                                                        (
                                                            <p>{t('ordersComponent.table')}</p>
                                                        )
                                    }
                                </dd>
                            </div>
                        </div>
                        <div className="footer-section-view row">
                            <span className="divisor"></span>
                            <div className="col-md-6">
                                <span className="text-gray light small-text">{t('createdBy')}: {order.created_by}</span>
                            </div>
                            <div className="col-md-6">
                                <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(order.created_at)}</span>
                            </div>
                            <div className="col-md-6">
                                {order.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {order.modified_by}</span>}
                            </div>
                            <div className="col-md-6">
                                {order.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(order.modified_at)}</span>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                        <div className="title-section-forms d-flex justify-content-center align-items-center mt-4 mb-2">
                            {t("ordersComponent.orderDetail")}
                        </div>
                    <div className="bold-container-content marginCard">
                    <div className="title-section-forms d-flex justify-content-end align-items-center m-0">
                        <div className="bold-infinite-title-section"  style={{ fontSize: '1rem' }}>Total: </div>
                        <div className="title-widget-complement " style={{ fontSize: '1.5rem' }}>{handlerApp.formatCurrency(order.order_value)}</div>
                        </div>
                        
                        <OrderDetails
                            order={order}
                        />
                    </div>
                </div>
            </div>
        </div>

    );
}

export default OrdersView;