import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import NoDataTables from "../../noDataTable/NoDataTables";
import axios from "axios";


const TransactionsCustomerMonth = () => {
    const endpoint = 'transactions-customer-company';
    const endpointDownload = 'transactions_month/check_invoice/download';
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState([]);

    const [t] = useTranslation("global");
    let title = t('mothnBills.transactions');

   
    const { modeTheme } = useContext(AppContext);
    const instance = axios.create({
        baseURL: process.env.REACT_APP_URL_BACKEND,
        headers: {
            Authorization: (localStorage.getItem("token")) ? localStorage.getItem("token") : ""
        }
    })

    const sendRequest = () => {
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint)
            .then(response => {
                if (response.success) {
                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    if (response.message !== "M001") {
                        handlerApp.handlerResponse(response);
                    }
                    handlerApp.setLoader(false);

                }
            })
            .catch(error => {
                //console.log(error)
                handlerApp.setLoader(false);

            })
    }
    useEffect(() => {
        sendRequest();
    }, []);

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'refresh':
                sendRequest();
                break;
            default:
                break;
        }
    }

    const columns = [
        {
            id: 'invoice_month_name',
            name: t('mothnBills.invoiceMonth'),
            selector: row => {
                const translatedMonth = t(`mothnBills.${row.invoice_month_name.trim()}`);
                return translatedMonth;
            },
            sortable: true,
        },
        {
            id: 'customer_name',
            name: t('mothnBills.customerName'),
            selector: row => row.customer_name,
            sortable: true
        },
        {
            id: 'total_invoice_value_paid',
            name: t('mothnBills.totalInvoiceValuePaid'),
            selector: row => handlerApp.formatCurrency(row.total_invoice_value_paid),
            sortable: true
        },
        {
            id: 'total_invoice_value_refund',
            name: t('mothnBills.totalInvoiceValueRefund'),
            cell: row => handlerApp.formatCurrency(row.total_invoice_value_refund),
            sortable: true,
        },
        {
            name: t('action'),
            cell: (row) => { 
                return (
                    <button className="btn-table-actions" onClick={() => handleDownloadPDF(row)} title={t("mothnBills.downloadBill")} >
                        <span className="material-symbols-outlined">download</span>
                    </button>
                )
            },
            ignoreRowClick: true,

        }
    ];

    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => t(`mothnBills.${item.invoice_month_name.trim()}`)?.toLowerCase().includes(filterText.toLowerCase())
            || item.customer_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.total_invoice_value_paid?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.total_invoice_value_refund?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    const handleDownloadPDF = async (row) => {
        try {
            handlerApp.setLoader(true);
            instance.get(endpointDownload, {
                responseType: 'blob',
                params: {
                    invoice_month: row.invoice_month,
                    language: localStorage.getItem("language")
                }
                
            })
            .then(response => {
                handlerApp.setLoader(false);
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', `Invoice_${row.invoice_month_name.trim()}.pdf`);
                        document.body.appendChild(link);
                        link.click();
                })
            } catch (error) {
                console.error('Error al descargar el PDF:', error);
            }
    };
    

    return (

        <div className="app container">
            <div className="title-section-forms">
                <h3>{title}</h3>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <div className="action-buttons">
                                <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        noDataComponent={<NoDataTables />}
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    )
}
export default TransactionsCustomerMonth;