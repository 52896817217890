import { createContext, useState } from "react";
import { useFetch } from "../hooks/useFecth";
import HandlerApp from "../utils/handlerApp";



const LocationContext = createContext();
const LocationContextProvider = ({children}) =>{

    const [location, setLocation] = useState("");
    const [locations, setLocations] = useState([])
    const api = useFetch();
    const handlerApp = HandlerApp();

    const checkLocations =()=>{
        api.get("administration/locations",{
            action: 'checkLocations'
        })
        .then(response =>{
            if(response.success){
                setLocations(response.data);
                localStorage.setItem("locations", JSON.stringify(response.data));
            }else{
                //console.log("error");
            }
        })
        .catch(error=>{
            handlerApp.showError("Error #E001");
        })
    }

    return (
        <LocationContext.Provider value={{location, setLocation, locations, setLocations, checkLocations}}>
            {children}
        </LocationContext.Provider>
    )
}

export { LocationContext, LocationContextProvider };
