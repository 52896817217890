import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { useTranslation } from "react-i18next";


const CouponsView = ()=>{
    const endpoint = "configuration/coupons";
    const [t,i18n] = useTranslation("global");
    const prefix = "coupons";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data,setData] = useState({});
    const [title,setTitle] = useState("");
    const nav = useNavigate();

    const {id} = useParams();
    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.coupon_name);
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    },[])

    const back = ()=>{
        nav(-1);
    }

    return(
        <div className="app">
            <div className="container bold-container-content2">
                <div className="d-flex align-items-center back-arrow">
                    <div className="d-flex row justify-content-between align-items-center arrow-back-icon2 ">
                        <span className="material-symbols-outlined arrowBack backArrow " role="button" onClick={back}>arrow_back</span>
                    </div>
                    <div style={{marginLeft: '10px'}}>{title}</div>
                </div>
            </div>
            <div className="container bold-container-content">
                <div className="row np-row">
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <dt>{t('couponComponent.name')}</dt>
                                <dd>{data.coupon_name}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('couponComponent.code')}</dt>
                                <dd>{data.coupon_code}</dd>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <dt>{t('couponComponent.descount')}</dt>
                                <dd>{data.coupon_descount+"%"}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('couponComponent.descount')}</dt>
                                <dd>{data.coupon_descount_type}</dd>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <dt>{t('couponComponent.starDate')}</dt>
                                <dd>{data.coupon_start_date}</dd>
                            </div>
                            <div className="col-md-6 mb-2">     
                                <dt>{t('couponComponent.endDate')}</dt>
                                <dd>{data.coupon_end_date}</dd>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.coupon_created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.coupon_modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.coupon_modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.coupon_modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CouponsView;