import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import NoDataTables from "../../noDataTable/NoDataTables";
import { LocationContext } from "../../../context/LocationContext";

const CloseCashierHistory = () => {
    const { id } = useParams(); 
    const endpoint = "/close/cashier"; 
    const [t, i18n] = useTranslation("global");
    const title = t('closeCashierScreen.historyCloseCashier');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false); // Estado para controlar el loader

    // Permisos
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const { app, modeTheme } = useContext(AppContext);

    const formatDate = (datetime) => {
        const date = new Date(datetime);
        const options = { month: 'short', day: '2-digit', year: 'numeric' };
        const locale = i18n.language === 'es' ? 'es-ES' : 'en-US';
        return new Intl.DateTimeFormat(locale, options).format(date).replace(/ de /g, ', ');
    };
            
    const formatTime = (datetime) => {
        const date = new Date(datetime);
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
        const locale = i18n.language === 'es' ? 'es-ES' : 'en-US';
        return new Intl.DateTimeFormat(locale, options).format(date);
    };

    const { location } = useContext(LocationContext);
    useEffect(() => {
        setData([])
        if (!location) {
            if (!handlerApp.getLocation()) {
                handlerApp.showAlertLocation();
            }
        } else {
            sendRequest();
        }
    }, [location]);



    useEffect(() => {
        setPermissions(app.permissions[endpoint]);
    }, [app]);

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'view':
                nav(`${endpoint}/view/${id}`);
                break;
            case 'refresh':
                sendRequest();
                break;
        }
    };

    const sendRequest = () => {
        setData([]);
        setLoading(true);
        api.get(`${endpoint}/${location}`, { limitOne: false })
            .then(response => {
                if (response.success) {
                    setData(response.data);
                } else {
                    if (response.message !== "M001") {
                        handlerApp.handlerResponse(response);
                    }
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
            .finally(() => {
                setLoading(false);
            });
    };



    const translateCloseCashierType = (type) => {
        return type ? t('closeCashierScreen.manualClosure') : t('closeCashierScreen.automaticClosing');
    };

    const columns = [
        {
            id: 'fecha',
            name: t('closeCashierScreen.date'),
            selector: row => formatDate(row.closingcasher_datetime),
            sortable: true,
        },
        {
            id: 'hora',
            name: t('closeCashierScreen.hour'),
            selector: row => formatTime(row.closingcasher_datetime),
            sortable: true,
        },
        {
            id: 'closingcasher_type',
            name: t('closeCashierScreen.type'),
            selector: row => row.closingcasher_type,
            sortable: true,
            cell: row => {
                return translateCloseCashierType(row.closingcasher_type);
            }
        },
        {
            id: 'encargado',
            name: t('closeCashierScreen.encargado'),
            selector: row => row.created_by,
            sortable: true,
        },
        {
            id: 'total_ingresos',
            name: t('closeCashierScreen.totalIngresos'),
            selector: row => handlerApp.formatCurrency(row.closingcasher_total),
            sortable: true,
        },
        {
            name: t('action'),
            cell: (row) => { 
                return handlerApp.actions(row, permissions, 'view', () => handlerAction(row.closingcasher_id, 'view')); 
            },
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => (item.closingcasher_debit && item.closingcasher_debit?.toLowerCase().includes(filterText.toLowerCase()))
            || item.created_by?.toString().toLowerCase().includes(filterText.toLowerCase())
            || formatDate(item.closingcasher_datetime).toLowerCase().includes(filterText.toLowerCase())
            || formatTime(item.closingcasher_datetime).toLowerCase().includes(filterText.toLowerCase())
            || item.closingcasher_type?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.closingcasher_id?.toString().toLowerCase().includes(filterText.toLowerCase())
            || translateCloseCashierType(item.closingcasher_type).toLowerCase().includes(filterText.toLowerCase())
            || item.closingcasher_total?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            <div className="title-section-forms">
                    <h3>{title}</h3>
                    <div className="d-flex">
                        <p className="text-details">{t('closeCashierScreen.historyDescription')}</p>
                    </div>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                <span className="material-symbols-outlined">autorenew</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        noDataComponent={<NoDataTables />}
                        persistTableHead
                        progressPending={handlerApp.setLoader(loading)}
                        theme={modeTheme ? "solarized" : ""}
                    />
                </div>
            </div>
        </div>
    );
};

export default CloseCashierHistory;