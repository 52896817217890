import { useContext, useEffect, useRef, useState } from "react";
import { Outlet } from "react-router";
import Aside from "./organisms/Aside";
import "./templates/BoldFramework.css";
import "./templates/BoldFrameworkDark.css";
import "./templates/App.css";
import "./templates/AppDark.css";
import "./templates/variables.css";
import MainHeader from "./organisms/MainHeader";
import Footer from "./organisms/Footer";
import Loader from "./templates/Loader";
import { LoaderContext, LoaderContextProvider } from "../context/LoaderContext";
import { useFetch } from "../hooks/useFecth";
import { AppContext } from "../context/AppContext";
import { LocationContext } from "../context/LocationContext";
import HandlerApp from "../utils/handlerApp";


const FirstDashboard = () => {  
    // TODO: revisar la doble carga de la solicitud al endpoint cuando recargo la pagina, se debe a la actualizacion del contexto de la aplicacion.
    const {loader,setLoader}    = useContext(LoaderContext);
    const {app,setApp}          = useContext(AppContext);
    const api                   = useFetch();
    const handlerApp            = HandlerApp();

    // Menu
    const [resultMenu, setResultMenu] = useState();
    const [menu, setMenu] = useState([]);
    const [loaderMenu,setLoaderMenu] = useState(true);

    // Responsive
    const [mobile,setMobile]    = useState(false);
    const [collapse,setCollapse]    = useState(false);
    const [run,setRun]          = useState(false);
    const [show,setShow]        = useState(false);
    const contentAside          = useRef();
    const globalContentAside    = useRef();
    const contentGlobalApp      = useRef();
    const menuRef               = useRef([]);
    const collapseProducts      = menuRef.current['collapse-Products'] !== undefined ? menuRef.current['collapse-Products'] : menuRef.current['collapse-Productos']

    // Locations
    const [locationLabel,setLocationLabel]  = useState("");
    const {location,setLocation, locations, setLocations } = useContext(LocationContext);

    const { checkLocations } = useContext(LocationContext);

    useEffect(() => {
        if (localStorage.getItem("token")) {
            checkLocations();
        }
    }, []);

    useEffect(()=>{
        setLabelLocation(localStorage.getItem("currentLocation"));
    },[locations])

    const handleLocation = (id)=>{
        setLocation(id);
        localStorage.setItem('currentLocation',id);
        setLabelLocation(id);
        // setApp({...app,location:id});
        setLocation(id);
    }

    useEffect(()=>{
        if (localStorage.getItem("token")) {
            // menu - aside 
            getMenu();
            getUserTuto()
            // Locations
            let locationList = JSON.parse(localStorage.getItem("locations"));
            if(localStorage.getItem("currentLocation") !== null && localStorage.getItem("currentLocation") !== undefined && localStorage.getItem("currentLocation") !== ""){
                setLocation(localStorage.getItem("currentLocation"));
            }
            setLocations(locationList);

            // Responsive
            let isMobile = (window.innerWidth < 780) ? true: false;
            setMobile(isMobile);
            if(!isMobile){
                setShow(true);
            }
            window.addEventListener('resize',()=>{
                let isMobile = (window.innerWidth < 780) ? true: false;
                setMobile(isMobile);
            })
        }
    },[])

    // Menu - Aside
    const getMenu = ()=>{
        api.post("/menu")
        .then(response => {
            setResultMenu(response.data)
            setLoaderMenu(false);
        })
        .catch(error => {
            setLoaderMenu(false);
        });
    }

    const getUserTuto = ()=>{
        // handlerApp.setLoader(true)
        api.get("/administration/users", {
            action: "getUserTutoInfo"
        })
        .then(response => {
            if(response.success){
                if(response.data[0].user_tutorial == 0){
                    setRun(true)
                    setLoaderMenu(false);
                    handlerApp.setLoader(false)
                }else{
                    setRun(false)
                    setLoaderMenu(false);
                    // handlerApp.setLoader(false)
                }
            }else{
                setLoaderMenu(false);
                // handlerApp.setLoader(false)
            }
        })
        .catch(error => {
            setLoaderMenu(false);
        });
    }

    const initTuto = () => {
        setCollapse(true)
        setRun(true)
    }

    useEffect(() => {

        let aux = "";
        let parentMenus = [];
        let permissions = [];

        resultMenu?.forEach(title => {
            if (title.menu_parent !== aux && title.menu_parent !== '') {
                aux = title.menu_parent;
                let childrens = [];
                resultMenu.forEach(song => {
                    if (song.menu_parent === aux && song.menu_children !== '') {
                        childrens.push(song)
                        let perm = song.permissions;
                        perm = perm.split('|');
                        permissions[song.menu_children_action] = perm;
                        title.childrens = childrens;

                    }
                })
                parentMenus.push(title);
            }
        })
        setMenu(parentMenus);

        // Actualizacion de contexto
        setApp({...app,permissions, location});

    }, [resultMenu])

    // ---------------------- Responsive
    useEffect(()=>{
        if(!mobile){
            setShow(true)
        }
    },[mobile])

    useEffect(()=>{
        if(show){
            contentAside.current.className="aside show";
            contentGlobalApp.current.className="bold-container";
            if(mobile){
                globalContentAside.current.className="shadow-aside";
            }
        }else{
            if(mobile){
                contentAside.current.className="aside hide";
            }else{
                contentGlobalApp.current.className="bold-container full";
                contentAside.current.className = "aside hide";
            }
            globalContentAside.current.className="";
        }
    },[show])

    const toggleAside = ()=>{
        if(!mobile){
            contentGlobalApp.current.className="bold-container full";
            contentAside.current.className = "aside hide";
        }
        setShow(!show)
    }
    // ---------------------- /Responsive

    // Location
    const setLabelLocation = (id)=>{
        let element = locations.filter(e => e.location_id.toString() === id.toString() );
        if(element.length > 0){
            setLocationLabel(element[0]?.location_name);
        }else{
            setLocationLabel("Global")
        }
    };
    return (
        <>

            <div className="bold-application">
                <div ref={globalContentAside} className="global-content-aside">
                    <div className="menu-btn-toggle" role="button" onClick={toggleAside}>
                        {/* <span className="material-symbols-outlined">arrow_back_ios_new</span> */}
                        &times;
                    </div>
                    <div ref={contentAside} className={ mobile ? 'aside hide': 'aside'}>
                        <Aside loaderMenu={loaderMenu} menu={menu} run={run} setRun={setRun} menuRef={menuRef} collapse={collapse} setCollapse={setCollapse}/>
                    </div>
                </div>
                <div ref={contentGlobalApp}className="bold-container">
                        <MainHeader collapseProducts={collapseProducts} toggleAside={toggleAside} run={initTuto} locations={locations} handleLocation={handleLocation} locationLabel={locationLabel}/>
                        { loader && <Loader/>}
                        <Outlet />
                        <Footer />
                </div>
            </div>
        </>
    )
}
export default FirstDashboard;