import { Alert, CircularProgress, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../../assets/css/login.css"
import logo from "../../assets/img/bravus-azul-rojo.png";
import logoNegativo from "../../assets/img/logo-negativo.png";
import Swal from 'sweetalert2';
import { useForm } from "react-hook-form";

const ForgotPassword = () => {
   
    const [t, i18n] = useTranslation("global");
    const [isDarkMode, setIsDarkMode] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    useEffect(() => {
        const htmlElement = document.documentElement;
        const darkMode = localStorage.getItem('darkMode');
    
        if (darkMode === 'dark') {
            htmlElement.classList.add('dark');
            setIsDarkMode(true);
        } else if (darkMode === '') {
            htmlElement.classList.remove('dark');
            setIsDarkMode(false);
        } else {
            const themeUser = window.matchMedia('(prefers-color-scheme: dark)').matches;
            if (themeUser) {
                htmlElement.classList.add('dark');
                localStorage.setItem('darkMode', 'dark');
                setIsDarkMode(true);
            } else {
                htmlElement.classList.remove('dark');
                localStorage.setItem('darkMode', '');
                setIsDarkMode(false);
            }
        }
    }, []);

    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();

    const nav = useNavigate();

    const handleFormForgotPassword = (data) => {
        setLoader(true);

        axios.post(process.env.REACT_APP_URL_BACKEND + '/forgot-password', {
            action: 'request-password-change',
            fcorreo: data.fcorreo, // Cambiado para usar el valor de data
        }).then(response => {
            setLoader(false);
            setError();
            // Muestra el modal de SweetAlert2
            Swal.fire({
                icon: response.data.message == "M102" ? 'success' : 'error',
                title: '',
                text: t(response.data.message)
            });
            nav('/login');
        }).catch(error => {  
            setLoader(false); 
            setError(t(error.response.data.message));
        })
    }

    const handleRegisterClick = () => {
        nav('/register'); 
    }

    const handleLogin= () => {
        nav('/login'); 
    }

    return (
        <>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login" style={{ maxWidth: "500px", width: "70%" }} >
                        { isDarkMode ? <img src={logoNegativo} alt="logo-brava" className="logo-login logo-l"/> :
                        <img src={logo} alt="logo-brava" className="logo-login logo-l"/> }
                        <form onSubmit={handleSubmit(handleFormForgotPassword)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <TextField 
                                        label={t('email')}
                                        variant="standard"
                                        size="small"
                                        {...register('fcorreo', {
                                            required: true,
                                            maxLength: 80,
                                            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Z|a-z]{2,}$/i, // Email format regex
                                        })}
                                        error={errors.fcorreo != null}
                                        helperText={
                                            errors.fcorreo?.type === 'required'
                                                ? t('validationErrorMessages.required')
                                                : errors.fcorreo?.type === 'maxLength'
                                                    ? `${t('validationErrorMessages.long')} 80 ${t('validationErrorMessages.longSuffix')}`
                                                    : errors.fcorreo?.type === 'pattern'
                                                        ? t('validationErrorMessages.invalidEmailFormat')
                                                        : ''
                                        }
                                        fullWidth
                                    />
                                </div>
                            </div>
                            {error &&
                                <Alert className="mb-3" severity="error">{error}</Alert>
                            }
                            <p className='subtitle-text-login' style={{ textAlign: 'left',}}>{t('rememberPassword')}<a className="text-link" onClick={handleLogin}>{t('loginHere')}</a></p>
                            <center>
                                <Button className="bold-btn-dark-big fs-5" type="submit" disabled={loader}>
                                    {loader ? <CircularProgress size={24} /> : t('resetPassword')}
                                </Button>
                            </center>
                        </form>
                        <p className='subtitle-text-login' style={{ textAlign: 'center', marginTop: '2rem'}}>{t('userRegister.new')} <a className="text-link" onClick={handleRegisterClick}>{t('userRegister.register')}</a></p>
                        <div style={{marginTop: '2rem', backgroundColor: '#7b90c4', height: 1}}></div>
                        <p className='subtitle-text-login' style={{ textAlign: 'center', marginTop: '1rem', fontSize: '15px' }}>{t('userRegister.agree')} <a className="text-linkSub" href="https://posbrava.com/" target="_blank">{t('userRegister.privacyPolicy')}</a></p>
                    </div>
                </div>
            </div>
            {
                loader &&
                <>
                    <div className="content-loader">
                        <CircularProgress />
                    </div>
                </>
            }
        </>
    )
}

export default ForgotPassword;
