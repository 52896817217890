
import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { LocationContext } from "../../../context/LocationContext";
import Swal from "sweetalert2";
import NoDataTables from "../../noDataTable/NoDataTables";
import Joyride, { ACTIONS, EVENTS, STATUS, Step } from 'react-joyride';
import MassActionButtons from "../../molecules/MassActionButtons";


const Locations = () => {

    const endpoint = "administration/locations";
    const [t, i18n] = useTranslation("global");
    const title = t('locationComponent.location');
    const api = useFetch();
    const [data, setData] = useState([]);
    const nav = useNavigate();
    const [run, setRun] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [buttonSelected, setButtonSelected] = useState(false);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [toggledClearRows, setToggleClearRows] = useState(false);

    const { checkLocations } = useContext(LocationContext);

    // Permisos
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const { app, modeTheme } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 912);

    const handleResize = () => {
        setIsSmallScreen(window.innerWidth < 912);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRecords(id)
                break;
            case 'deleteSelected':
                const selectedIds = rowsSelected.map(row => row.location_id);
                deleteRecords(selectedIds);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            case 'activateSelected':
                updateSelectedRowsStatus(1);
                break;
            case 'deactivateSelected':
                updateSelectedRowsStatus(0);
                break;
        }
    }

    // data.
    useEffect(() => {
        sendRequest();
        getUserTuto()
    }, [])

    const sendRequest = () => {
        setData([])
        handlerApp.setLoader(true);
        api.get(endpoint)
            .then(response => {
                setData(response.data);
                handlerApp.setLoader(false);
            })
            .catch(error => {
                //console.log(error);
                handlerApp.setLoader(false);
            })
    }
    
    const deleteRecords = (ids) => {
        console.log(ids);
        const isMultiple = Array.isArray(ids);
        const alertTitle = isMultiple ? t('DeleteAlert.DeleteSelectedRecords') : t('DeleteAlert.DeleteRecord');
        const endpointUrl = isMultiple ? `${endpoint}/all` : `${endpoint}/${ids}`;
        let body = {
            codes: ids
        }
        Swal.fire({
            icon: 'info',
            title: alertTitle,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: t('DeleteAlert.delete'),
            confirmButtonColor: "red",
            cancelButtonText: t('cancel')
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
    
                const request = isMultiple 
                    ? api.deleteMethod(endpointUrl, body) 
                    : api.deleteMethod(endpointUrl);
    
                request
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            if (isMultiple) {
                                setButtonSelected(false);
                                setRowsSelected([]);
                                handleClearRows();
                            }
                            sendRequest();
                            checkLocations();
                        } else {
                            handlerApp.handlerResponse(response);
                            handlerApp.setLoader(false);
                        }
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const updateSelectedRowsStatus = (status) => {
        const actionName = status === 1 ? 'activateSelected' : 'deactivateSelected';
        const actionTitle = status === 1 ? `${t('activeAlert.activateSelectedRecords')}` : `${t('inactiveAlert.inactiveSelectedRecords')}`;
        Swal.fire({
            icon: 'info',
            title: actionTitle,
            showCancelButton: true,
            confirmButtonText: status === 1 ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`,
            confirmButtonColor: status === 1 ? "#08a215" : "#d32f2f",
            cancelButtonText: `${t('cancel')}`
        }).then((result) => {
            if (result.isConfirmed) {
                handlerApp.setLoader(true);
                const ids = rowsSelected.map(row => row.location_id);
                const body = {
                    action: 'updateStatus',
                    status: status,
                    codigos: ids
                };
                api.put(endpoint+'/all', body)
                    .then(response => {
                        if (response.success) {
                            handlerApp.showOk(response);
                            setButtonSelected(false);
                            setRowsSelected([]);
                            sendRequest();
                            handleClearRows();
                        } else {
                            handlerApp.handlerResponse(response);
                        }
                        handlerApp.setLoader(false);
                    })
                    .catch(error => {
                        handlerApp.showError(t(error.message));
                        handlerApp.setLoader(false);
                    });
            }
        });
    };

    const optionRequest = (id, action) => {
        Swal.fire({
            title: action === 'active' ? `${t('activeAlert.activeRecord')}` : `${t('inactiveAlert.inactiveAlert')}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: action === 'active' ? "#08a215" : "#be2a2a",
            cancelButtonColor: "#636c74",
            cancelButtonText: `${t('cancel')}`,
            confirmButtonText: action === 'active' ? `${t('activeAlert.active')}` : `${t('inactiveAlert.inactive')}`
        }).then((result) => {
            if (result.isConfirmed) {
                executeAction(id, action);
            }
        });
    }
    
    const executeAction = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                    checkLocations();
                } else {
                    if (response.message !== "M001") {
                        handlerApp.handlerResponse(response);
                    }
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            });
    }

    const rowSelected = (state) => {
        setButtonSelected(state.selectedRows.length > 0);
        setRowsSelected(state.selectedRows);
    };

    const handleClearRows = () => {
        setToggleClearRows(!toggledClearRows);
    };

    const stepsP = [
        {
            target: 'body',
            content: <div>
                <h4>{t('tutorial.warning')}</h4>
                <p>{t('tutorial.sameFunction')}</p>
            </div>,
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'center',
        },
        {
            target: '#search',
            content: t('tutorial.search'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
        },
        {
            target: '#add',
            content: t('tutorial.add'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'left',

        },
        {
            target: '#autorenew',
            content: t('tutorial.autorenew'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'left',
        },
        {
            target: '.view',
            content: t('tutorial.view'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
        },
        {
            target: '.update',
            content: t('tutorial.update'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'left',
        },
        {
            target: '.delete',
            content: t('tutorial.delete'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'left',
        },
        {
            target: '.inactive',
            content: t('tutorial.inactive'),
            locale: { skip: <strong aria-label="skip">{t('tutorial.skip')}</strong> },
            placement: 'left',
        },
    ]

    const columns = [
        {
            id: 'location_id',
            name: 'Id',
            selector: row => row.location_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            id: 'state_name',
            name: t('state'),
            selector: row => row.state_name,
            sortable: false
        },
        {
            name: t('action'),
            cell: (row) => { return handlerApp.actions(row, permissions, 'location', handlerAction) },
            // cell:(row) => { return actions(row)},
            ignoreRowClick: true,
            button: true,
            minWidth: "160px"
        }
    ];

    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => (item.location_name && item.location_name?.toLowerCase().includes(filterText.toLowerCase()))
            || item.location_id?.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.state_name?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRun(false);
            setStepIndex(0);
            updateStatusUserTutorial();

        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);

            if (sidebarOpen && index === 0) {
                setTimeout(() => {
                    setRun(true);
                }, 400);
            } else if (sidebarOpen && index === 1) {
                setRun(false);
                setSidebarOpen(false);
                setStepIndex(nextStepIndex);
                setTimeout(() => {
                    setRun(true);
                }, 400);
            } else if (index === 2 && action === ACTIONS.PREV) {
                setRun(false);
                setSidebarOpen(true);
                setStepIndex(nextStepIndex);
                setTimeout(() => {
                    setRun(true);
                }, 400);
            } else {
                setSidebarOpen(false);
                setStepIndex(nextStepIndex);
            }
        }
    };

    const initTuto = () => {
        setRun(true)
        setStepIndex(0)
    }

    const getUserTuto = () => {
        // handlerApp.setLoader(true)
        api.get("/administration/users", {
            action: "getUserTutoInfo"
        })
            .then(response => {
                if (response.success) {
                    if (response.data[0].user_tutorial_location == 0) {
                        setRun(true)
                        // handlerApp.setLoader(false)
                    } else {
                        setRun(false)
                        // handlerApp.setLoader(false)
                    }
                } else {
                    // handlerApp.setLoader(false)
                }
            })
            .catch(error => {
            });
    }

    const updateStatusUserTutorial = () => {
        handlerApp.setLoader(true)
        api.put("/administration/users/update-status-tutorial/user_tutorial_location/1")
            .then(response => {
                handlerApp.setLoader(false)
            })
            .catch(error => {
                handlerApp.setLoader(false)

            });
    }


    return (
        <div className="app container">
            <div className="title-section-forms">
                        <h3>{title}</h3>
                        <p>{t("description.location")}</p>
            </div>
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <div className="section-events">
                            <div id='search' className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>

                            {!isSmallScreen && buttonSelected && (
                                <MassActionButtons
                                    permissions={permissions}
                                    handlerAction={handlerAction}
                                    t={t}
                                />
                            )}
                            <div className="action-buttons">
                                <button className="btn-action-dash mb-2" onClick={initTuto} ><span className="material-symbols-outlined" id='my-step' >developer_guide</span></button>

                                {permissions?.indexOf("create") !== -1 && (
                                    <button id='add' className="btn-table-actions" onClick={() => { handlerAction(null, 'create') }}>
                                        <span className="material-symbols-outlined">add</span>
                                    </button>
                                )}
                                <button id='autorenew' className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                    <span className="material-symbols-outlined">autorenew</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    {isSmallScreen && buttonSelected && (
                        <MassActionButtons
                            permissions={permissions}
                            handlerAction={handlerAction}
                            t={t}
                        />
                    )}
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle}
                        subHeader
                        noDataComponent={<NoDataTables />}
                        selectableRows={(permissions?.indexOf("inactive") !== -1 || permissions?.indexOf("delete") !== -1)}
                        onSelectedRowsChange={rowSelected}
                        clearSelectedRows={toggledClearRows}
                        
                        theme={modeTheme ? "solarized" : ""}
                    />
                    <Joyride
                        continuous
                        run={run}
                        scrollToFirstStep
                        showProgress
                        showSkipButton
                        callback={handleJoyrideCallback}
                        stepIndex={stepIndex}
                        steps={stepsP}
                        styles={ modeTheme ?
                            {options: 
                                {
                                arrowColor: '#20222a',
                                primaryColor: '#da3452',
                                backgroundColor: '#20222a',
                                textColor: '#FFFFFF'
                             },
                             buttonBack: {
                                marginRight: 10,
                                color: '#fff',
                              },
                            }
                             :
                            {options: 
                                {primaryColor: '#202742'}
                            }
                        }
                    />
                </div>
            </div>
        </div>
    )
}

export default Locations;